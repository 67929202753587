<template>
  <v-date-picker
    v-model="modelValue"
    class="border-none"
    is-range
    is-required
    is24hr
    update-on-input
    :mode="dateTimeMode ? 'datetime' : 'date'"
    :from-page="fromPage"
    :columns="screens({ default: 2 })"
    :first-day-of-week="2"
    :min-date="localMinDate"
    :max-date="localMaxDate"
    :locale="$i18n.locale"
    :masks="masks"
  />
</template>

<script>
import { calendarSettings, getRange } from '../helpers'

export default {
  props: {
    locale: {
      type: String,
      required: true
    },
    selectedDate: {
      type: Object,
      required: true
    },
    fromPage: {
      type: Object,
      required: true
    },
    noPastDates: {
      type: Boolean,
      default: false
    },
    noFutureDates: {
      type: Boolean,
      default: false
    },
    dateTimeMode: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  emits: ['update', 'clear'],
  computed: {
    localMinDate() {
      if (this.minDate instanceof Date) {
        return this.minDate
      }
      return this.noPastDates ? new Date() : null
    },
    localMaxDate() {
      if (this.maxDate instanceof Date) {
        return this.maxDate
      }
      return this.noFutureDates ? new Date() : null
    },
    modelValue: {
      get() {
        return this.selectedDate
      },
      set(value) {
        const { start, end } = getRange({
          value,
          minDate: this.localMinDate,
          maxDate: this.localMaxDate,
          dateTimeMode: this.dateTimeMode
        })
        this.$emit('update', start, end)
        this.$emit('clear')
      }
    },
    masks() {
      return calendarSettings.formats
    }
  },
  methods: {
    screens(args) {
      if (process.client) {
        return this.$screens(args)
      } else {
        return 2
      }
    }
  }
}
</script>
