<template>
  <div class="grid grid-cols-4 gap-x-4 mt-1 mb-4">
    <el-button
      v-for="button in buttonList"
      :key="button.type"
      class="block outline-none py-0 focus:shadow-outline"
      text
      @click="handleShortCutClick(button.type, button.range)"
      v-text="button.label"
    />
  </div>
</template>

<script>
import { getShortcutButtons, getRange } from '../helpers'

export default {
  name: 'ShortCutButtons',
  props: {
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  computed: {
    buttonList() {
      return getShortcutButtons(this, {
        minDate: this.minDate,
        maxDate: this.maxDate
      })
    }
  },
  methods: {
    handleShortCutClick(type, value) {
      const { start, end } = getRange({
        value,
        minDate: this.minDate,
        maxDate: this.maxDate,
        dateTimeMode: true
      })

      this.$emit('update', start, end)
      this.$emit('update-short-cut', type)
    }
  }
}
</script>
