<template>
  <div class="flex justify-end">
    <!-- Reset -->
    <el-button @click="$emit('reset')">
      {{ $t('components.date_picker.title.reset') }}
    </el-button>

    <!-- Apply -->
    <el-button
      type="primary"
      class="border-th-primary bg-th-primary"
      @click="$emit('apply-action')"
    >
      {{ $t('components.date_picker.title.apply') }}
    </el-button>
  </div>
</template>
