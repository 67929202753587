<template>
  <el-popover
    v-model:visible="isOpen"
    :placement="placement"
    trigger="click"
    :popper-class="popperClass"
  >
    <div class="p-3">
      <div v-show="!simple" class="mb-4 font-bold text-sm">{{ message }}</div>

      <date-range-picker
        :locale="locale"
        :selected-date="selectedDates"
        :from-page="fromPage"
        :no-past-dates="noPastDates"
        :no-future-dates="noFutureDates"
        :date-time-mode="dateTimeMode"
        :min-date="localMinDate"
        :max-date="localMaxDate"
        @update="update"
      />
      <short-cut-buttons
        v-if="!simple && !noPastDates"
        :min-date="localMinDate"
        :max-date="localMaxDate"
        @update="update"
      />
      <apply-button v-if="!simple" @apply-action="applyAction" @reset="reset" />
    </div>
    <template #reference>
      <el-button
        icon="Calendar"
        class="text-th-color-input"
        :style="buttonStyle"
      >
        {{ buttonText }}
      </el-button>
    </template>
  </el-popover>
</template>

<script>
import { format } from 'date-fns'
import {
  currentDateRange,
  getDateFormat,
  getDateTimeFormat
} from '@/utils/date'
import { popoverPlacements } from '@/constants'
import ShortCutButtons from './short-cut-buttons'
import DateRangePicker from './date-range-picker'
import ApplyButton from './components/apply-button'
import { isValidDate, getDate } from '../helpers'

export default {
  name: 'DateRangePickerParent',
  components: {
    DateRangePicker,
    ShortCutButtons,
    ApplyButton
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        start: null,
        end: null,
        showDateText: false
      }),
      validator: ({ start, end }) => {
        const startIsDate = start ? isValidDate(start) : true
        const endIsDate = end ? isValidDate(end) : true
        return startIsDate && endIsDate
      }
    },
    locale: {
      type: String,
      default: navigator.language
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    hideDateInButton: {
      type: Boolean,
      default: false
    },
    buttonWidth: {
      type: String,
      default: 'auto'
    },
    dateFormat: {
      type: String,
      default: undefined
    },
    simple: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'right',
      validator: (val) => popoverPlacements.includes(val)
    },
    noPastDates: {
      type: Boolean,
      default: false
    },
    noFutureDates: {
      type: Boolean,
      default: false
    },
    preventDefaultDates: {
      type: Boolean,
      default: false
    },
    dateTimeMode: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    autoApplyOnReset: {
      type: Boolean,
      default: true
    },
    closeOnReset: {
      type: Boolean,
      default: false
    },
    cssClasses: {
      type: String,
      default: ''
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    endDateTime: {
      type: String,
      default: 'currentTimeIfToday',
      validator: (val) => ['currentTimeIfToday', 'alwaysEndOfDay'].includes(val)
    }
  },
  emits: ['update:modelValue', 'clear'],
  data() {
    return {
      buttonStyle: {
        width: this.buttonWidth
      },
      isOpen: false,
      selectedDates: {},
      fromPage: {},
      showDateText: this.modelValue.showDateText,
      defaultDate: currentDateRange()
    }
  },
  computed: {
    localMinDate() {
      if (this.minDate instanceof Date) {
        return this.minDate
      }
      return this.noPastDates ? new Date() : null
    },
    localMaxDate() {
      if (this.maxDate instanceof Date) {
        return this.maxDate
      }
      return this.noFutureDates ? new Date() : null
    },
    popperClass() {
      return `w-auto ${this.cssClasses}`
    },
    message() {
      if (!this.showDateText === false) {
        return this.$t('components.date_picker.instructions')
      }

      return this.formatDateToText(
        this.selectedDates.start,
        this.selectedDates.end
      )
    },
    buttonText() {
      if (this.hideDateInButton || this.showDateText === false) {
        return (
          this.buttonLabel || this.$t('components.date_picker.title.select')
        )
      }
      return this.formatDateToText(
        this.selectedDates.start,
        this.selectedDates.end
      )
    },
    computedDateFormat() {
      const defaultFormat = this.dateTimeMode
        ? getDateTimeFormat()
        : getDateFormat()
      return this.dateFormat || defaultFormat
    }
  },
  watch: {
    modelValue: 'setSelectedDates'
  },
  async mounted() {
    if (!this.preventDefaultDates) {
      this.setSelectedDates()
    }
  },
  methods: {
    setSelectedDates() {
      const start = this.modelValue.start
        ? getDate(this.modelValue.start)
        : this.defaultDate.start
      const end = this.modelValue.end
        ? getDate(this.modelValue.end)
        : this.defaultDate.end
      this.selectedDates = { start, end }
    },
    update(start, end) {
      let modifiedEnd = getDate(end)
      if (this.endDateTime === 'alwaysEndOfDay') {
        modifiedEnd = new Date(this.selectedDates.end)
        modifiedEnd.setHours(23, 59, 59, 999)
      }
      this.selectedDates = {
        start: getDate(start),
        end: modifiedEnd
      }

      this.fromPage = {
        month: start.getMonth() + 1,
        year: start.getFullYear()
      }
      if (this.simple || this.autoClose) {
        this.applyAction(start, end)
      }
    },
    applyAction() {
      const dates = {
        start: this.selectedDates.start?.toISOString(),
        end: this.selectedDates.end?.toISOString()
      }
      this.$emit('update:modelValue', dates)
      this.isOpen = false
    },

    reset() {
      this.selectedDates = {
        start: this.defaultDate.start,
        end: this.defaultDate.end
      }
      this.fromPage = {
        month: this.selectedDates.start.getMonth() + 1,
        year: this.selectedDates.start.getFullYear()
      }
      if (this.autoApplyOnReset) {
        this.$emit('update:modelValue', {})
        this.$emit('clear')
      }
      if (this.closeOnReset) {
        this.isOpen = false
      }
    },
    formatDateToText(inputStart, inputEnd) {
      const startDate = inputStart || this.defaultDate.start
      const endDate = inputEnd || this.defaultDate.end
      const start = format(startDate, this.computedDateFormat)
      const end = format(endDate, this.computedDateFormat)
      if (start === end) {
        return start
      } else {
        const to = this.$t('components.date_picker.title.to')
        return `${start} ${to} ${end}`
      }
    }
  }
}
</script>
